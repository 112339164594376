<template>
  <div>
    <FormCreatePatient></FormCreatePatient>
  </div>
</template>

<script>
import FormCreatePatient from '@core/components/FormsRegister/FormCreatePatient.vue'

export default {
  components: {
    FormCreatePatient,
  },
  data() {
    return {}
  },
}
</script>

<style>

</style>
